
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { isWechat } from '@/utils/utils'
import { formatDate } from '@/utils/date'
@Component({
	components: {},
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	private isWechat = false
	private packageList: any[] = []
	private boxList: any[] = []
	private isLoading = false
	private outboundData = {
		status: 2,
		package_attr_total: 0
	}
	private editForm = {
		packageCode: '',
		outboundCode: ''
	}

	private boxDrawer = {
		visible: false,
		boxList: [],
		isLoading: false,
		searchForm: {
			channelId: ''
		},
		pageConfig: {
			total: 0,
			size: 10,
			current_page: 1
		},
		selectedRowCodes: []
	}

	@Watch('boxDrawer.pageConfig.current_page')
	onPagination() {
		this.getBoxList()
	}

	private channels: any = []
	get list() {
		const arr: any[] = []
		return arr.concat(this.boxList, this.packageList)
	}

	async created() {
		const { outbound_code = '' }: any = this.$route.query
		this.isWechat = isWechat()
		this.editForm.outboundCode = outbound_code
		if (outbound_code) {
			this.getOutbound()
		} else {
			await this.createOutbound()
		}
		this.getChannels()
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}
	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					this.editForm.packageCode = result[result.length - 1]
					this.addPackage()
				}
			})
		})
	}
	async emptyOutbound() {
		const isConfirm = await this.$confirm('确认清空出库单？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (isConfirm) {
			await this.$axios
				.post(
					`/v1/jobline/warehouse-record/truncate/${this.editForm.outboundCode}`
				)
				.catch(() => false)
			this.packageList = []
			this.boxList = []
		}
	}
	async removeItem({ row, $index }: any) {
		const isConfirm = await this.$confirm('确认删除？', '提示', {
			confirmButtonText: '确认',
			cancelButtonText: '取消'
		}).catch(() => false)
		if (!isConfirm) return false
		const { box_code, package_attr_num, container_type } = row
		const { outboundCode } = this.editForm
		const { data } = await this.$axios
			.post(`v1/jobline/warehouse-record/remove/${outboundCode}`, {
				code: container_type == 'box' ? box_code : package_attr_num
			})
			.catch(() => {
				return { data: null }
			})
		if (data) {
			if (container_type == 'box') {
				this.boxList.splice($index, 1)
			} else {
				this.packageList.splice($index, 1)
			}
		}
	}

	printLabel(code: string) {
		const baseUrl = process.env.VUE_APP_API_BASE_URL
		return `${baseUrl}v1/jobline/generate/package-bar-code/${code}?title=出库单`
	}
	async getOutbound() {
		const { outboundCode } = this.editForm
		if (!outboundCode) return false
		if (this.isLoading) return false
		this.isLoading = true
		const { data } = await this.$axios
			.get(`/v1/jobline/warehouse-record/${this.editForm.outboundCode}`, {})
			.catch(e => {
				this.$message.error(e.message)
				return { data: null }
			})
			.finally(() => {
				this.isLoading = false
			})
		if (!data) return
		const { boxs, package_attrs } = data
		const boxList: any[] = boxs.map(
			({ code, package_attr_count, channel_name }: any) => {
				return {
					count: package_attr_count,
					box_code: code,
					weight: '-',
					length: 0,
					width: 0,
					height: 0,
					container_type: 'box',
					package_attr_num: '-',
					status_pay: '-',
					express_title: '-',
					channel_name: channel_name
				}
			}
		)
		const packageList: any[] = package_attrs.map(
			({
				express_title,
				status_pay,
				length,
				width,
				height,
				weight,
				package_attr_num
			}: any) => {
				return {
					count: 1,
					box_code: '-',
					weight: weight ? (weight / 1000).toFixed(2) + 'KG' : '-',
					length: length || 0,
					width: width || 0,
					height: height || 0,
					container_type: 'package',
					package_attr_num,
					status_pay,
					express_title: express_title,
					channel_name: '-'
				}
			}
		)
		this.packageList = packageList
		this.boxList = boxList
		this.outboundData = {
			status: data.status,
			package_attr_total: data.package_attr_total
		}
	}
	async confirmOutbound() {
		const { outboundCode } = this.editForm
		const isConfirm = await this.$confirm(
			'确认出库后包裹和箱子信息不允许修改，确认出库？',
			'提示',
			{
				confirmButtonText: '确认',
				cancelButtonText: '取消'
			}
		).catch(() => false)
		if (!isConfirm) return false
		const { data } = await this.$axios
			.post('/v1/jobline/', {
				box_code: outboundCode
			})
			.catch(() => {
				return {
					data: null
				}
			})
		if (data) {
			this.$message.success('出库成功')
		}
	}
	async addPackage() {
		const { outboundCode, packageCode } = this.editForm
		console.log(packageCode)

		const { data } = await this.$axios
			.post(`/v1/jobline/warehouse-record/${outboundCode}`, {
				code: packageCode
			})
			.catch(e => {
				return { data: null }
			})
		if (!data) return false
		const {
			container_type,
			box_code,
			height,
			express_title,
			length,
			width,
			weight,
			status_pay,
			box_package_attr_total,
			package_attr_num
		} = data
		const item = {
			container_type,
			box_code: box_code || '-',
			package_attr_num: package_attr_num || '-',
			count: container_type == 'box' ? box_package_attr_total : 1,
			height,
			length,
			width,
			express_title,
			weight: weight ? (weight / 1000).toFixed(2) + 'KG' : '-',
			status_pay
		}
		if (container_type == 'box') {
			this.boxList.push(item)
		} else {
			this.packageList.push(item)
		}
		this.editForm.packageCode = ''
	}
	async createOutbound() {
		const { data } = await this.$axios.post('/v1/jobline/warehouse-record', {})
		this.editForm.outboundCode = data
		this.$router
			.replace({
				query: {
					outbound_code: data
				}
			})
			.catch(err => err)
	}

	async selectBox() {
		this.boxDrawer.visible = true
		this.getBoxList()
	}

	async getBoxList() {
		const { channelId = undefined } = this.boxDrawer.searchForm
		const { size, current_page } = this.boxDrawer.pageConfig
		this.boxDrawer.isLoading = true
		const params = {
			page_size: size,
			page_index: current_page,
			channelId
		}
		const { data } = await this.$axios
			.get('/v1/jobline/box', {
				params: params
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.boxDrawer.isLoading = false))
		if (!data) {
			return
		}
		this.boxDrawer.boxList = data.list
		this.boxDrawer.pageConfig.total = data.page.total
	}

	async getChannels() {
		const { data } = await this.$axios.get('v1/jobline/channels', {})
		this.channels = data
	}

	async addBoxToWarehouseRecord({ row, $index }: any) {
		const { code } = row
		this.editForm.packageCode = code
		const ret = await this.addPackage()
		if (false !== ret) {
			row.warehouse_record_code = this.editForm.outboundCode
		}
	}
}
